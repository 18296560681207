export default {
  backgroundWrapper: 'background-wrapper absolute top-70 xl:top-90 w-full',
  content: {
    pageTitle: 'page-title relative text-center text-white py-08 lg:py-15 font-montserratBold font-bold text-14 lg:text-22 tracking-onethree lg:tracking-twoeight leading-leading20',
    mainWrapper: 'md:px-20 w-full md:pb-50 PGEemailVerificationWrapper',
    brandMainWrapper: 'brandMainWrapper md:px-20 w-full md:pb-50 bg-white relative -top-50 min-h-550 lg:min-h-480',
    wrapper: 'bg-white relative text-center w-full container mx-auto pb-35 md:pb-50 xl:pb-70',
    cascadewrapper: 'cascadewrapper bg-white relative text-center w-full container mx-auto pb-35 md:pb-50 xl:pb-70',
    pageSubTitle: 'w-11/12 md:w-7/12 lg:w-6/12 xl:w-6/12 mx-auto font-montserratSemiBold font-semibold text-center px-35 md:px-28 lg:px-0 pt-40 md:pt-50 pb-15 md:pb-25 xl:pt-60 xl:pb-35 text-24 md:text-30 xl:text-38 leading-wider xl:leading-leading42',
    subText: 'subText font-montserratlight font-light w-11/12 md:w-7/12 lg:w-6/12 xl:w-5/12 px-20 md:px-17 lg:px-30 xl:px-0 mx-auto pb-20 xl:pb-35 text-14 md:text-16 xl:text-18 leading-leading20 md:leading-lightmedium xl:leading-widest',
    loginWrapper: 'w-10/12 md:w-7/12 xl:w-6/12 xxl:w-5/12 px-10 md:px-0 mx-auto',
    cascadeloginWrapper: 'cascadeloginWrapper w-10/12 md:w-7/12 xl:w-6/12 xxl:w-5/12 px-10 md:px-0 mx-auto',
  },
}
