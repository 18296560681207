import { detect } from 'detect-browser'
import _ from 'lodash'

const browser = detect()
export const breakpoints = [375, 768, 1024, 1366, 1920]

export function getImageUrl(
  imageUrl,
  width = 'auto',
) {
  let end_point_url
  if (typeof imageUrl !== 'string') return ''
  if (width) {
    end_point_url = `${imageUrl}?w=${width}`
  } else end_point_url = imageUrl
  return end_point_url
}
export function getVideoUrl(videoUrl) {
  if (typeof videoUrl !== 'string') return ''

  const end_point_url = videoUrl
  return end_point_url
}

// function getChromeVersion() {
//   const raw = browser.version.match(/([0-9]+)\./)
//   return raw ? parseInt(raw[1], 10) : false
// }

export const getCdnImage = (url, dimension_params = {}) => {
  if (typeof url !== 'string') return ''
  let end_point_url = `${url}`
  if (!url.includes('svg')) {
    let image_format = 'webp'
    // if (browser.name === 'chrome') {
    //   if (getChromeVersion() > 84) {
    //     image_format = 'avif'
    //   }
    // }
    if (browser?.name === 'safari') {
      image_format = 'png'
    }

    end_point_url = `${end_point_url}?fm=${image_format}`
  }
  if (!_.isEmpty(dimension_params)) {
    // const width = dimension_params.w
    end_point_url = `${end_point_url}`
  }
  return end_point_url.replace(/([^:])(\/\/+)/g, '$1/')
}

export function getVideoSrcSet(videoUrl, originalImgWidth, minWidth) {
  let filteredSizes = breakpoints.filter((size) => size <= originalImgWidth)

  if (minWidth) {
    filteredSizes = breakpoints.filter((size) => size >= minWidth)
  }
  return filteredSizes
    .map(
      (videoSize) => ({ src: `${getVideoUrl(videoUrl).replace(/q_auto/g, `w_${videoSize},q_auto`)}`, width: videoSize }),
    )
}

export function getSrcSet(imageUrl, originalImgWidth, minWidth) {
  let filteredSizes = breakpoints.filter((size) => size <= originalImgWidth)
  if (minWidth) {
    filteredSizes = breakpoints.filter((size) => size >= minWidth)
  }

  return filteredSizes
    .map(
      (imgSize) => `${getCdnImage(imageUrl, {
        w: `${imgSize}`,
      })} ${imgSize}w`,
    )
    .join(',')
}

export function getSrcSetMobileAndDesktop(mobileImageUrl, desktopImageUrl) {
  return breakpoints
    .map(
      (imgSize) => `${getCdnImage(imgSize > 991 ? desktopImageUrl : mobileImageUrl, {
        w: `${imgSize}`,
      })} ${imgSize}w`,
    )
    .join(',')
}

export function getVideoSrcSetW(videoUrl, width) {
  return getVideoUrl(videoUrl).replace(/q_auto/g, `w_${width},q_auto`)
}

export function getSrcSetW(imageUrl, width) {
  return getCdnImage(imageUrl, {
    w: `${width}`,
  })
}

export const getOptimizedImage = (url, size) => `${url}?w=${size.w}&h=${size.h}`

export const getSrcW = (imageUrl, width) => {
  if (imageUrl) {
    if (width && width < 4000) {
      return `${imageUrl}?w=${width}&fm=webp&q=80`
    }
    return `${imageUrl}?fm=webp&q=80`
  }
  return ''
}
