import React from 'react'
import Helmet from 'next/head'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import Schema from '../SEO/Schema'

function Metadata(props) {
  const {
    pageTitle,
    metaTitle,
    metaDescription,
    metaKeywords,
    metaTagKeywords,
    metaImage,
    openGraphPageTitle,
    openGraphDescription,
    openGraphImage,
    openGraphSiteName,
    openGraphType,
    router,
    addGenericSchema,
    h1Text,
  } = props
  const url = router.asPath
  const urlWithoutQuery = url.indexOf('?') > -1 ? url.substr(0, url.indexOf('?')) : url
  const canonicalUrl = urlWithoutQuery[urlWithoutQuery.length - 1] !== '/' ? `${urlWithoutQuery}/` : urlWithoutQuery
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        {metaTitle && <meta name='title' content={metaTitle} />}
        {(metaKeywords || metaTagKeywords) && <meta name='keywords' content={`${metaKeywords || ''}${metaTagKeywords || ''}`} />}
        {metaDescription && (
          <meta name='description' content={metaDescription} />
        )}
        {metaImage && <meta name='image' content={metaImage.url} />}
        {openGraphPageTitle && (
          <meta property='og:title' content={metaTitle} />
        )}
        {openGraphDescription && (
          <meta property='og:description' content={metaDescription} />
        )}
        {openGraphImage && (
          <meta property='og:image' content={openGraphImage.url} />
        )}
        {openGraphSiteName && (
          <meta property='og:site_name' content={openGraphSiteName} />
        )}
        {openGraphType && (
          <meta property='og:type' content={openGraphSiteName} />
        )}
        {process.env.SITE_DOMAIN && (
          <>
            <meta
              property='og:url'
              content={`${process.env.SITE_DOMAIN}${router.asPath}`}
            />
            <link
              rel='canonical'
              href={`${process.env.SITE_DOMAIN}${canonicalUrl.length === 1 ? '' : canonicalUrl}`}
            />
          </>
        )}
      </Helmet>
      {addGenericSchema && (
        <Schema
          router={router}
          headline={pageTitle}
          description={metaDescription}
          image={metaImage && metaImage.url}
        />
      )}
      {h1Text
      && (
      <span
        role='region'
        aria-labelledby='title'
      >
        <span id='title' className=' absolute -top-50 z-0'>
          {h1Text && <h1 className='fixed -top-75 campaignTop text-16 w-auto text-white block'>{h1Text}</h1>}
        </span>
      </span>
      )}
    </>
  )
}

Metadata.propTypes = {
  pageTitle: PropTypes.string,
  metaTitle: PropTypes.string,
  metaKeywords: PropTypes.string,
  metaTagKeywords: PropTypes.string,
  metaDescription: PropTypes.string,
  metaImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  openGraphPageTitle: PropTypes.string,
  openGraphDescription: PropTypes.string,
  openGraphSiteName: PropTypes.string,
  openGraphType: PropTypes.string,
  openGraphImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  addGenericSchema: PropTypes.bool,
  router: PropTypes.instanceOf(Object).isRequired,
  h1Text: PropTypes.string,
}

Metadata.defaultProps = {
  metaTitle: 'PG Good everyday',
  metaKeywords: '',
  metaTagKeywords: '',
  metaDescription: '',
  metaImage: null,
  openGraphPageTitle: '',
  openGraphDescription: '',
  openGraphImage: null,
  pageTitle: 'PG Good everyday',
  openGraphSiteName: null,
  openGraphType: null,
  addGenericSchema: true,
  h1Text: null,
}

export default withRouter(Metadata)
