const getEXIFOrientation = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()

  reader.onload = (event) => {
    try {
      const view = new DataView(event.target.result)

      if (view.getUint16(0, false) !== 0xffd8) resolve(-2)

      const length = view.byteLength
      let offset = 2

      while (offset < length) {
        const marker = view.getUint16(offset, false)
        offset += 2

        if (marker === 0xffe1) {
          offset += 2
          if (view.getUint32((offset), false) !== 0x45786966) {
            resolve(-1)
          }
          const little = view.getUint16((offset += 6), false) === 0x4949
          offset += view.getUint32(offset + 4, little)
          const tags = view.getUint16(offset, little)
          offset += 2

          for (let i = 0; i < tags; i += 1) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              resolve(view.getUint16(offset + i * 12 + 8, little))
            }
          }
        } else if ((marker && 0xff00) !== 0xff00) break
        else offset += view.getUint16(offset, false)
      }
      resolve(-1)
    } catch (error) {
      reject(error)
    }
  }

  reader.readAsArrayBuffer(file.slice(0, 64 * 1024))
})

const loader = ({ src, quality }) => {
  function isImage(url) {
    return /\.(svg||gif||png)$/.test(url)
  }
  function isJpg(url) {
    return /\.(jpg||jpeg)$/.test(url)
  }
  if (isJpg(src)) {
    return `${src}?fm=webp&q=${quality || 80}`
  }
  if (isImage(src)) {
    return src
  } return `${src}&q=${quality || 80}`
}

module.exports = {
  getCorrectedRotation: async (file) => {
    const exifValue = await getEXIFOrientation(file)
    let mirror = false
    switch (exifValue) {
      case 2:
        mirror = true
        break
      case 4:
        mirror = true
        break
      case 5:
        mirror = true
        break
      case 7:
        mirror = true
        break
      default:
        break
    }
    return {
      mirror,
      rotateDeg: 0,
    }
  },
  loader,
}
