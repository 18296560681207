import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import Img from 'next/image'
import screenWidth from '../../constants/screenWidth'
import { getCdnImage, getSrcW } from '../../adapters/cloudinary.adapters'
import CouponsStore from '../../containers/Coupons/Coupons.store'
import { loader } from '../../helpers/image.helper'
import { standardEventForAsset } from '../../containers/AuthContainer/standardEventErrorLog'
import { STATUS_1000, STATUS_1001 } from '../../constants/status-code'

const Image = (props) => {
  const {
    altText,
    image,
    desktopImage,
    smartphoneImage,
    tabletImage,
    tooltip,
    className,
    lazyLoad,
    lazyloadOffset,
    priority,
    name,
    loading,
    sizes,
  } = props

  const LazyLoadWrapper = (child) => (lazyLoad !== 'false' ? (
    <LazyLoad offset={lazyloadOffset}>{child}</LazyLoad>
  ) : (
    child
  ))

  const handleLoad = () => {
    standardEventForAsset(STATUS_1001, { })
  }

  const handleError = (e) => {
    const imageDetails = {
      desktopImage, smartphoneImage, tabletImage, image,
    }
    standardEventForAsset(STATUS_1000, { imageDetails, pageUrl: e.currentTarget.baseURI })
  }

  if (image?.url) {
    let url = ''
    if (image.url.includes('/coupons/')) {
      url = `${CouponsStore.imageSuffix}${image.url}`
    } else {
      url = `${image.url}`
    }
    return LazyLoadWrapper(
      <div className={className || 'w-full'}>
        <Img
          src={getCdnImage(url, { w: image.width }, 10)}
          alt={altText !== null ? `${altText.trim()}` : `${name}`}
          width={image.width || 275}
          height={image.height || 117}
          sizes={sizes}
          tooltip={tooltip}
          loader={loader}
          priority={priority}
          onLoad={handleLoad}
          onError={handleError}
        />
      </div>,
    )
  }
  return LazyLoadWrapper(
    <picture>
      <source
        media='(max-width: 580px)'
        srcSet={getSrcW(smartphoneImage && smartphoneImage.url)}
      />
      <source
        media={`(max-width:${screenWidth.smartPhoneWidth}px)`}
        srcSet={getSrcW(smartphoneImage && smartphoneImage.url, 768)}
      />
      <source
        media={`(max-width:${screenWidth.tabletWidth}px)`}
        srcSet={getSrcW(tabletImage && tabletImage.url, 1024)}
      />
      <source
        srcSet={getSrcW(desktopImage && desktopImage.url)}
      />

      <img
        className={className}
        src={getSrcW(desktopImage && desktopImage?.url, 1440)}
        alt={altText !== null ? `${altText.trim()}` : `${name}`}
        width={desktopImage?.width || 1440}
        height={desktopImage?.height || 400}
        {...(loading ? { loading } : {})}
        onLoad={handleLoad}
        onError={handleError}
      />
    </picture>,
  )
}

Image.propTypes = {
  altText: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  desktopImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  smartphoneImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  tabletImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  tooltip: PropTypes.string,
  children: PropTypes.instanceOf(Object),
  lazyLoad: PropTypes.string,
  isSVG: PropTypes.string,
  priority: PropTypes.bool,
  name: PropTypes.string,
}

Image.defaultProps = {
  altText: 'image',
  className: '',
  image: null,
  desktopImage: null,
  smartphoneImage: null,
  tabletImage: null,
  tooltip: '',
  children: null,
  lazyLoad: 'true',
  lazyloadOffset: 250,
  isSVG: 'false',
  priority: false,
  name: 'image',
}

export default Image
