import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import { withRouter } from 'next/router'
import DOMPurify from 'isomorphic-dompurify'

function Schema({
  router,
  headline,
  description,
  image,
  datePublished,
  pageType,
  publisherType,
  dateModified,
  videoSchemaData,
}) {
  const publishedDate = new Date(datePublished)
  const modifiedDate = new Date(dateModified)
  const updatedDate = `${modifiedDate.getFullYear()}-${modifiedDate.getMonth()
    + 1}-${modifiedDate.getDate()}`
  const formatedDate = `${publishedDate.getFullYear()}-${publishedDate.getMonth()
    + 1}-${publishedDate.getDate()}`
  return (
    <Head>

      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(`
          {
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "P&G Good Everyday",
            "url": "https://www.pggoodeveryday.com/",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.pggoodeveryday.com/search/?t={search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }                                    
        `)
            .replace(/\s+/g, ' ')
            .trim(),
        }}
      />
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(`
          {
            "@context":"http://schema.org",
            "@type":"Organization",
            "name":" P&G Good Everyday ",
            "url":"https://www.pggoodeveryday.com/",
         "logo":"https://images.ctfassets.net/5tx4oa3foqys/6FdXjbJ9f3A0oQO23UMTtv/e8be70e40b661c6a9119b519f45231e1/icon-72x72.png",
            "parentorganization":"Procter & Gamble",
            "sameAs":[
               "https://www.instagram.com/pggoodeveryday/",
               "https://twitter.com/pggoodeveryday",
               "https://www.youtube.com/pggoodeveryday"
            ]
         }
                                            
        `)
            .replace(/\s+/g, ' ')
            .trim(),
        }}
      />
      { pageType !== 'Article' && (
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(`
            {
                "@context": "https://schema.org",
                "@type": "${pageType}",
                ${
                  pageType === 'Article'
                    ? `"mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${process.env.SITE_DOMAIN}${router?.asPath.split('?')[0]}"
                },`
                    : ''
                }
                "${
                  pageType === 'Article' ? 'headline' : 'name'
                }": "${headline}",
                "description": "${description}",
                ${
                  image
                    ? `"image": "${image}",`
                    : ''
                }
                "publisher": {
                    "@type": "${publisherType}",
                    "name": "P&G Good Everyday"
                    ${
                      publisherType !== 'ProfilePage'
                        ? `,"logo": {
                        "@type": "ImageObject",
                        "url": "https://images.ctfassets.net/5tx4oa3foqys/4QfoWrwE2we59zitBBVNHa/6c02b7c069bf47c55bca9d655e93e632/logo.png",
                        "width": "100px",
                        "height": "100px"
                    }`
                        : ''
                    }
                }
                ${datePublished ? `,"datePublished": "${formatedDate}"` : ''}
                ${dateModified ? `,"dateModified":"${updatedDate}"` : ''}
            }
        `)
            .replace(/\s+/g, ' ')
            .trim(),
        }}
      />
      )}
      {videoSchemaData && (
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(`
          {
           "@context": "http://schema.org",
           "@type": "VideoObject",
           "name": "${videoSchemaData.name}",
           "description": "${videoSchemaData.description}",
           "thumbnailUrl": "${videoSchemaData.thumbnailUrl}",
           "uploadDate": "${videoSchemaData.uploadDate}",
           "duration": "${videoSchemaData.duration}",
           "embedUrl": "${videoSchemaData.embedUrl}",
         
         "interactionStatistic": {
                 "@type": "InteractionCounter",
                 "interactionType": { "@type": "http://schema.org/WatchAction" },
                 "userInteractionCount": "32"
         }
        }
        `)
            .replace(/\s+/g, ' ')
            .trim(),
        }}
      />
      )}

      {
        pageType === 'Article' && (
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(`
              { 

                "@context": "https://schema.org", 
              
                "@type": "Article", 
              
                "mainEntityOfPage": { 
              
                  "@type": "WebPage", 
              
                  "@id": "${process.env.SITE_DOMAIN}${router?.asPath.split('?')[0]}"
              
                }, 
              
                "headline": "${headline}", 
              
                "description": "${description}", 
              
                "image": "${image}",   
              
                "author": { 
              
                  "@type": "Organization", 
              
                  "name": "pggoodeveryday.com" 
              
                },   
              
                "publisher": { 
              
                  "@type": "Organization", 
              
                  "name": "pggoodeveryday.com", 
              
                  "logo": { 
              
                    "@type": "ImageObject", 
              
                    "url": "https://images.ctfassets.net/5tx4oa3foqys/4QfoWrwE2we59zitBBVNHa/6c02b7c069bf47c55bca9d655e93e632/logo.png" 
              
                  } 
              
                }, 
              
                "datePublished": "${formatedDate}",
                "dateModified":"${updatedDate}"
              
              } 
                                              
          `)
                .replace(/\s+/g, ' ')
                .trim(),
            }}
          />
        )
      }
    </Head>
  )
}

Schema.propTypes = {
  router: PropTypes.instanceOf(Object).isRequired,
  headline: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  datePublished: PropTypes.string,
  dateModified: PropTypes.string,
  pageType: PropTypes.string,
  publisherType: PropTypes.string,
  videoSchemaData: PropTypes.oneOfType([PropTypes.object]),
}

Schema.defaultProps = {
  description: '',
  image: null,
  headline: '',
  datePublished: '2020-01-14',
  pageType: 'WebPage',
  publisherType: 'ProfilePage',
  dateModified: null,
  videoSchemaData: null,
}

export default withRouter(Schema)
